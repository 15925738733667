
import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/layout'
import PostMetadataDisplay from '../../components/metadata'
import RenderedRichText from '../../components/richText'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SEO } from '../../components/seo'

const NewsPost = ({ data, location }) => {
  return (
    <Layout location={location}>
      {data.contentfulNewsPost.heroImage &&
        <GatsbyImage
          image={data.contentfulNewsPost.heroImage.gatsbyImageData}
          alt={data.contentfulNewsPost.heroImage.description}
          loading="eager"
          className="responsive-gatsby-image"
          />}
      <Link to="/news" className="uppercase">News</Link>
      <h1 className="mb-2">{data.contentfulNewsPost.title}</h1>
      <PostMetadataDisplay post={data.contentfulNewsPost} className="mt-2 mb-4" />
      <RenderedRichText richText={data.contentfulNewsPost.bodyText} />
    </Layout>
  )
}

export const query = graphql`
  query ($title: String) {
    contentfulNewsPost(title: {eq: $title}) {
      heroImage {
        gatsbyImageData(width: 1000, placeholder: TRACED_SVG)
        description
        contentful_id
        __typename
      }
      author
      createdAt(formatString: "x")
      title
      bodyText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000, placeholder: BLURRED)
            file {
              contentType
              url
            }
            __typename
          }
        }
      }
    }
  }
`

export default NewsPost

export const Head = ({ data }) => (
  <SEO title={data.contentfulNewsPost.title} />
)

